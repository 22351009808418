<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="visible"
    center
    top="5vh"
    width="756px"
  >
    <el-form ref="form" v-loading="queryLoading" :model="form" :rules="rules" label-width="auto" size="small">
      <el-row>
        <el-col :span="8">
          <el-form-item :label="$t('title.PlatForm')" prop="plat">
            <el-select
              v-model="form.plat"
              :disabled="!isAdd"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              filterable
              @change="handlePlatformChange"
            >
              <el-option
                v-for="item in platformList"
                :key="item.platformId"
                :label="item.platformName"
                :value="item.platformId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.site')" prop="site">
            <el-select
              v-model="form.site"
              :disabled="!isAdd"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              filterable
              @change="handleSiteChange"
            >
              <el-option
                v-for="item in siteList"
                :key="item.siteId"
                :label="item.siteName"
                :value="item.siteId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.shopName')" prop="shop">
            <el-select
              v-model="form.shop"
              :disabled="!isAdd"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              filterable
              @change="handleShopChange"
            >
              <el-option
                v-for="item in shopList"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('page.ComplianceWarehouse')" prop="warehouseList">
            <div class="el-input--small">
              <div class="el-input__inner" style="display: flex;align-items: center;flex-wrap: wrap">
                <el-tag v-for="(item, index) in form.warehouseList" :key="item.id" closable size="small" @close="handleDelWarehouse(index)">
                  {{ item.wareCode }}
                </el-tag>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider />
    <el-descriptions v-loading="queryLoading" :colon="false" :column="2">
      <el-descriptions-item :label-style="{'line-height': '32px'}" label="选择仓库添加至履约仓">
        <el-select
          v-model="selectWarehouseList"
          filterable
          multiple
          size="small"
          value-key="wareCode"
        >
          <el-option
            v-for="item in complianceWarehouseList"
            :key="item.id"
            :label="item.wareCode"
            :value="item"
          />
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <el-button size="small" type="primary" @click="handleAddWarehouse">
          {{ $t('title.Add') }}
        </el-button>
      </el-descriptions-item>
    </el-descriptions>
    <template #footer>
      <el-button @click="handleClose">{{ $t('order.Cancel') }}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{ $t('page.sure') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getShopList, getSiteList } from '@/api/select'
import { addComplianceWarehouse, getAddComplianceWarehouseList, updateComplianceWarehouse } from './apis/addDialog'

export default {
  name: 'AddDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    },
    platformList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      queryLoading: false,
      selectWarehouseList: [],
      complianceWarehouseList: [],
      siteList: [],
      shopList: [],
      form: {
        plat: '',
        site: '',
        shop: '',
        warehouseList: []
      },
      rules: {
        plat: [{ required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }],
        site: [{ required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }],
        shop: [{ required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }],
        warehouseList: [{ required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }]
      },
      submitLoading: false
    }
  },
  computed: {
    isAdd() {
      return !this.row
    },
    title() {
      return this.isAdd ? this.$t('title.Add') : this.$t('title.Edit')
    },
    submitParams() {
      const { plat, site, shop } = this.form
      const platformName = this.platformList.find(e => e.platformId === plat)?.platformName
      const siteName = this.siteList.find(e => e.siteId === site)?.siteName
      const shopName = this.shopList.find(e => e.shopId === shop)?.shopName
      return {
        ...this.form,
        plat: platformName,
        site: siteName,
        shop: shopName
      }
    },
    submitFn() {
      return this.isAdd ? addComplianceWarehouse : updateComplianceWarehouse
    },
    queryListParams() {
      const { plat, site, shop } = this.form
      return {
        platformId: plat ? [plat] : [],
        siteId: site ? [site] : [],
        shopId: shop ? [shop] : []
      }
    }
  },
  watch: {
    visible: {
      async handler(val) {
        if (val && this.row) {
          this.queryLoading = true
          try {
            const { plat, site, shop } = this.row
            this.form.plat = this.platformList.find(e => e.platformName.toLowerCase() === plat?.toLowerCase())?.platformId
            await this.getSiteList()
            this.form.site = this.siteList.find(e => e.siteName.toLowerCase() === site?.toLowerCase())?.siteId
            await this.getShopList()
            this.form.shop = this.shopList.find(e => e.shopName.toLowerCase() === shop?.toLowerCase())?.shopId
            await this.getComplianceWarehouseList()
            this.form.warehouseList = this.row.wareCode?.split(',').map(e => {
              return this.complianceWarehouseList.find(w => w.wareCode.toLowerCase() === e.toLowerCase())
            }).filter(e => e)
            this.form.warehouseList.forEach(e => {
              const index = this.complianceWarehouseList.findIndex(item => item.wareCode === e.wareCode)
              this.complianceWarehouseList.splice(index, 1)
            })
          } finally {
            this.queryLoading = false
          }
        }
      }
    }
  },
  methods: {
    handlePlatformChange() {
      this.form.site = ''
      this.form.shop = ''
      this.form.warehouseList = []
      this.selectWarehouseList = []
      this.complianceWarehouseList = []
      this.getSiteList()
    },
    handleSiteChange() {
      this.form.shop = ''
      this.form.warehouseList = []
      this.selectWarehouseList = []
      this.complianceWarehouseList = []
      this.getShopList()
    },
    handleShopChange() {
      this.form.warehouseList = []
      this.selectWarehouseList = []
      this.complianceWarehouseList = []
      this.getComplianceWarehouseList()
    },
    async getSiteList() {
      const { platformId } = this.queryListParams
      if (!platformId.length) {
        this.siteList = []
        return
      }
      const { datas } = await getSiteList({
        platformId
      })
      this.siteList = datas || []
    },
    async getShopList() {
      const { platformId, siteId } = this.queryListParams
      if (!platformId.length || !siteId.length) {
        this.shopList = []
        return
      }
      const { datas } = await getShopList({
        platformId,
        siteId
      })
      this.shopList = datas || []
    },
    async getComplianceWarehouseList() {
      this.form.warehouseList = []
      const { plat, site, shop } = this.submitParams
      if (!plat || !site || !shop) {
        this.complianceWarehouseList = []
        return
      }
      const { datas } = await getAddComplianceWarehouseList({
        plat,
        site,
        shop
      })
      this.complianceWarehouseList = datas || []
    },
    handleClose() {
      if (this.submitLoading) {
        return
      }
      this.form = {
        plat: '',
        site: '',
        shop: '',
        warehouseList: []
      }
      this.selectWarehouseList = []
      this.complianceWarehouseList = []
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
    },
    handleAddWarehouse() {
      this.form.warehouseList = this.form.warehouseList.concat(this.selectWarehouseList)
      this.selectWarehouseList.forEach(e => {
        const index = this.complianceWarehouseList.findIndex(item => item.wareCode === e.wareCode)
        this.complianceWarehouseList.splice(index, 1)
      })
      this.selectWarehouseList = []
    },
    handleDelWarehouse(index) {
      const el = this.form.warehouseList.splice(index, 1)
      this.complianceWarehouseList = this.complianceWarehouseList.concat(el)
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          const { code, msg } = await this.submitFn(this.submitParams).finally(() => {
            this.submitLoading = false
          })
          if (code === 0) {
            this.$message.success(msg)
            this.$emit('refresh')
            this.handleClose()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-tag + .el-tag {
  margin-left: 3px;
}
</style>
