import request from '@/utils/request'

export function getComplianceWarehouseList(data, signal) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/warehouse/select`,
    method: 'post',
    data,
    signal
  })
}

export function updateComplianceWarehouseStatus(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/warehouse/status`,
    method: 'post',
    data
  })
}
