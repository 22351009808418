<template>
  <div>
    <el-form ref="form" :model="form" inline label-width="0" size="small">
      <el-form-item label="" prop="platform">
        <el-select
          v-model="form.plat"
          :placeholder="$t('title.PlatForm')"
          clearable
          filterable
        >
          <el-option
            v-for="item in platformList"
            :key="item.platformId"
            :label="item.platformName"
            :value="item.platformId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="siteId">
        <el-select
          v-model="form.site"
          :placeholder="$t('title.site')"
          clearable
          collapse-tags
          filterable
          multiple
        >
          <el-option
            v-for="item in siteList"
            :key="item.siteId"
            :label="item.siteName"
            :value="item.siteId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="shopId">
        <el-select
          v-model="form.shop"
          :placeholder="$t('title.shopName')"
          clearable
          collapse-tags
          filterable
          multiple
        >
          <el-option
            v-for="item in shopList"
            :key="item.shopId"
            :label="item.shopName"
            :value="item.shopId"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t('page.search') }}
        </el-button>
        <el-button @click="handleReset">
          {{ $t('page.reset') }}
        </el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button type="primary" @click="handleOpenAddDialog(null)">{{ $t('page.AddComplianceWarehouse') }}</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <!--平台-->
      <vxe-column :title="$t('title.platform')" field="plat" />
      <!--站点-->
      <vxe-column :formatter="emptyCellFormatter" :title="$t('title.site')" field="site" />
      <!--店铺-->
      <vxe-column :formatter="emptyCellFormatter" :title="$t('title.shopName')" field="shop" />
      <!--履约仓-->
      <vxe-column :title="$t('page.ComplianceWarehouse')" field="wareCode">
        <template v-slot="{row}">
          {{ row.wareCode ? row.wareCode.split(',').join('\n') : '' }}
        </template>
      </vxe-column>
      <!--创建时间-->
      <vxe-column :title="$t('page.createTime')" field="createTime" width="160" />
      <!--状态-->
      <vxe-column :title="$t('page.status')">
        <template v-slot="{row}">
          <el-switch
            :value="row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="val => handleUpdateStatus(row, val)"
          />
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.operate')" fixed="right">
        <template v-slot="{row}">
          <el-button type="text" @click="handleOpenAddDialog(row)">{{ $t('title.Edit') }}</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <add-dialog
      :platform-list="platformList"
      :row.sync="currentRow"
      :visible.sync="addDialogVisible"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import { getPlatformListByUser, getShopList, getSiteList } from '@/api/select'
import AddDialog from './AddDialog.vue'
import { getComplianceWarehouseList, updateComplianceWarehouseStatus } from './apis/list'

export default {
  name: 'ComplianceWarehouseConfigList',
  components: { AddDialog },
  data() {
    return {
      platformList: [],
      siteList: [],
      shopList: [],
      form: {
        plat: '',
        site: [],
        shop: []
      },
      queryLoading: false,
      maxHeight: 0,
      tableData: [],
      currentRow: null,
      addDialogVisible: false
    }
  },
  computed: {
    queryListParams() {
      const { plat, site, shop } = this.form
      return {
        platformId: plat ? [plat] : [],
        siteId: site,
        shopId: shop
      }
    },
    queryParams() {
      const { plat, site, shop } = this.form
      const platformName = this.platformList.find(e => e.platformId === plat)?.platformName
      const siteName = this.siteList.filter(e => site.includes(e.siteId)).map(e => e.siteName)
      const shopName = this.shopList.filter(e => shop.includes(e.shopId)).map(e => e.shopName)
      return {
        ...this.form,
        plat: platformName,
        site: siteName,
        shop: shopName
      }
    }
  },
  watch: {
    'form.plat'() {
      this.form.site = []
      this.getSiteList()
    },
    'form.site'() {
      this.form.shop = []
      this.getShopList()
    }
  },
  created() {
    this.handleQuery()
    this.getPlatformList()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    emptyCellFormatter({ row, column }) {
      return row[column.property] || '/'
    },
    async getPlatformList() {
      const { datas } = await getPlatformListByUser({
        userId: ''
      })
      this.platformList = datas || []
    },
    async getSiteList() {
      const { platformId } = this.queryListParams
      if (!platformId.length) {
        this.siteList = []
        return
      }
      const { datas } = await getSiteList({
        platformId
      })
      this.siteList = datas || []
    },
    async getShopList() {
      const { platformId, siteId } = this.queryListParams
      if (!platformId.length || !siteId.length) {
        this.shopList = []
        return
      }
      const { datas } = await getShopList({
        platformId,
        siteId
      })
      this.shopList = datas || []
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.controller) {
        this.controller.abort()
        setTimeout(() => {
          this.queryLoading = true
        })
      }
      this.controller = new AbortController()
      const signal = this.controller.signal
      const { datas } = await getComplianceWarehouseList(this.queryParams, signal).finally(() => {
        this.queryLoading = false
      })
      this.tableData = datas || []
    },
    handleReset() {
      this.form = {
        plat: '',
        site: [],
        shop: []
      }
      this.handleQuery()
    },
    handleOpenAddDialog(row) {
      this.currentRow = row
      this.addDialogVisible = true
    },
    async updateStatus(row, val) {
      const { plat, site, shop } = row
      const { msg, code } = await updateComplianceWarehouseStatus({
        plat,
        site,
        shop,
        status: val
      })
      if (code === 0) {
        this.$message.success(msg)
        await this.handleQuery()
      }
    },
    async handleUpdateStatus(row, val) {
      if (!val) {
        this.$confirm('该店铺对应的所有规则将被禁用', this.$t('page.Prompt'), {
          closeOnClickModal: false,
          type: 'warning',
          beforeClose: async(action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = instance.cancelButtonLoading = true
              this.updateStatus(row, val).finally(() => {
                instance.confirmButtonLoading = instance.cancelButtonLoading = false
              })
              done()
            } else {
              done()
            }
          }
        })
        return
      }
      await this.updateStatus(row, val)
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
