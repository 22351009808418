import request from '@/utils/request'

export function addComplianceWarehouse(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/warehouse/insert`,
    method: 'post',
    data
  })
}

export function updateComplianceWarehouse(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/warehouse/update`,
    method: 'post',
    data
  })
}

export function getAddComplianceWarehouseList(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/warehouse/warehouseCode`,
    method: 'post',
    data
  })
}
